.footer-static-container {
    position: relative;
    background: #fff;
    margin-top: 0px;
}
.wrapper_footer_static {
    padding: 70px 0 50px;
}
.footer-static-container .footer_static .footer-links h2 {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 0;
    line-height: 1;
    font-size: 1.33em;
    color: #124a2f;
    font-family: inherit;
}
ul {
    list-style: none;
}
.footer-static-container .footer_static .footer-links li {
    margin-bottom: 10px;
}
.footer-static-container .footer_static .footer-links a {
    color: #999;
    text-decoration: none;
}
.section-instagram h2 {
    margin-bottom: 20px;
    line-height: 1;
    font-size: 1.33em;
    color: #124a2f;
    font-family: inherit;
}
.social-link {
    position: relative;
    text-align: center;
}
.social-link:before {
    content: "";
    height: 1px;
    width: 100%;
    background: #389244;
    left: 0;
    top: 49%;
    position: absolute;
}
.social-link.express:before {
    background: #f01266;
}
.widget.block {
    margin: 20px 0;
}
.widget {
    clear: both;
}
.social-link ul {
    text-align: center;
    margin: 0;
    display: inline-block;
    z-index: 1;
    background: #fff;
    position: relative;
    padding: 0 35px;
}
.social-link ul li {
    list-style: none;
    display: inline-block;
    position: relative;
    margin-right: 20px;
}
.footer_introduce .footer-contact.express li,
.footer_introduce .footer-contact.express li a,
.social-link .express ul li a span { color: #f01266; }
.social-link ul li a span {
    font-size: 27px;
    text-align: center;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    color: #389244;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-facebook-f:before, .fa-facebook:before {
    content: "\f09a";
}
.footer_introduce .footer-contact {
    margin-bottom: 30px;
    padding-bottom: 2px;
    text-align: center;
}
.footer_introduce .footer-contact li {
    position: relative;
    padding-left: 30px;
    margin-right: 50px;
    display: inline-block;
    font-size: 16px;
    color: #389244;
}
.footer_introduce .footer-contact li:before {
    
    font-size: 24px;
    position: absolute;
    top: -3px;
    left: 0;
    width: 20px;
    height: 20px;
}
.footer_introduce .footer-contact li a {
    color: #389244;
}
.footer-bottom {
    padding: 0 0 20px;
    background: #fff;
    text-align: center;
    position: relative;
}
.footer_address_payment p {
    margin-bottom: 15px;
}
.footer-bottom .copyright {
    font-size: 14px;
    margin: 0;
}