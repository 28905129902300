.location-search-input {
  height: auto !important;
  min-height: 30px;
  resize: none;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #f1f1f1;
  color: #0d1136;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  padding: 15px 18px 0 18px;
  box-sizing: border-box;
  -webkit-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease;
}
.location-search-input:hover,
.location-search-input:focus {
  outline: 0;
}

.location-search-input:focus {
  border-color: #389244;
}

::placeholder {
  color: #77798c;
}
